<template>
  <div class="m-pg-sub">
    <page-cover code="7" layer="rgba(0,0,0,.3)">
      <h2 class="m-page-title">{{$t('page.reserve.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/reserve' }">{{$t('page.reserve.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>申请</el-breadcrumb-item>
      </el-breadcrumb>
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <div class="m-group">
        <div class="m-group_head">
          <div class="m-group_head-title">
            <i class="m-group_head-icon zm-icon zm-icon-jianyi"></i>
            <span class="m-group_head-title_text">仪器预约单</span>
          </div>
        </div>
        <div class="m-group_body">
          <el-form ref="applyFm" class="applyFm" label-width="110px" :model="applyFm" :rules="applyFmRules" label-suffix=":">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="预约设备名称">
                  {{applyFm.Lab_apparatusName}}
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="预约单号">
                  {{applyFm.AppointmentFormNO}}
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item label="预约单名称">
                  {{applyFm.Name}}
                </el-form-item>
              </el-col>
              <hr /><br />
              <!-- <el-col :span="12">
                <el-form-item label="实验室名称">
                  {{labApparatus.Typelab | dictMap(dict.list_lab_Typelab)}}
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item label="申请类型" prop="AppointmentType">
                  <el-row :gutter="10">
                    <el-col :span="14">
                      <el-select v-model="applyFm.AppointmentType" placeholder="请选择">
                        <el-option
                          v-for="item in dict.AppointmentType"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="9">
                      <el-button
                        v-if="applyFm.AppointmentType == 1"
                        size="small"
                        type="primary"
                        icon="el-icon-download"
                        @click="onAgreementDownload('inside')">
                        (所内人员)合同下载
                      </el-button>
                      <el-button
                        v-else size="small"
                        type="primary"
                        icon="el-icon-download"
                        @click="onAgreementDownload('outside')">
                        (所外人员)合同下载
                      </el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请人" prop="A_Applicant">
                  <el-input v-model="applyFm.A_Applicant"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请日期" prop="A_Date">
                  <el-date-picker
                    v-model="applyFm.A_Date"
                    type="date"
                    value-format="yyyy-MM-dd"
                    style="width: 100%;">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请主体" prop="A_ApplicationUnit">
                  <el-input v-model="applyFm.A_ApplicationUnit"></el-input>
                    <el-tooltip class="item" effect="dark" content="XX项目/课题/实验室/部门或者个人等" placement="top">
                  <span class="tip-icon el-icon-question"></span>
                </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="主体负责人" prop="A_Principal">
                  <el-input v-model="applyFm.A_Principal"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" prop="Tel">
                  <el-input v-model="applyFm.Tel"></el-input>
                  <el-tooltip class="item" effect="dark" content="建议填写手机号" placement="top">
                    <span class="tip-icon el-icon-question"></span>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="数据发送邮件" prop="Send_email">
                  <el-input v-model="applyFm.Send_email"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="科学问题" prop="remarks">
                  <el-input v-model="applyFm.remarks" type="textarea"></el-input>
                  <el-tooltip class="item" effect="dark" content="有关您的项目所涉及的宏观的科学问题、意义，以及该项目要解决的具体的科学问题" placement="top">
                    <span class="tip-icon el-icon-question"></span>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="分析目的" prop="remarks2FXMD">
                  <el-input v-model="applyFm.remarks2FXMD" type="textarea"></el-input>
                  <el-tooltip class="item" effect="dark" content="您最终需要什么样的分析数据，例如，图像或同位素比值等，大概需要多少数据量，需要什么样的精度和准确度来解决您的科学问题" placement="top">
                    <span class="tip-icon el-icon-question"></span>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="预期成果" prop="remarks2YQCG">
                  <el-input v-model="applyFm.remarks2YQCG" type="textarea"></el-input>
                  <el-tooltip class="item" effect="dark" content="数据结果将以何种方式报道，SCI/EI/核心/会议摘要/报告材料" placement="top">
                    <span class="tip-icon el-icon-question"></span>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合同上传" prop="DContractFile">
                  <el-upload
                    name="fileInput"
                    :headers="{
                      Authorization: token ? 'Bearer ' + token : ''
                    }"
                    :limit="1"
                    show-file-list
                    :action="uploadAgreement.url"
                    accept=".doc,.docx"
                    :on-progress="onUploadAgreementProgress"
                    :on-error="onUploadAgreementError"
                    :on-success="onUploadAgreementSuccess"
                    :on-exceed="onUploadAgreementExceed"
                    :on-remove="onUploadAgreementRemove">
                    <el-button
                      :loading="uploadAgreement.loading"
                      size="small"
                      type="primary"
                      icon="el-icon-upload">
                      上传合同
                    </el-button>
                    <!-- <div slot="tip" class="el-upload__tip">{{uploadAgreement.fileName}}</div> -->
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="m-group">
        <div class="m-group_head">
          <div class="m-group_head-title">
            <i class="m-group_head-icon zm-icon zm-icon-list"></i>
            <span class="m-group_head-title_text">样品清单</span>
          </div>
          <div class="m-group_head-action">
            <el-button type="primary" @click="onDownloadImportTpl" style="margin-right: 10px;">{{$t('action.downloadImportTpl')}}</el-button>
            <el-upload
              class="btn-import"
              name="fileInput"
              :headers="{
                Authorization: token ? 'Bearer ' + token : ''
              }"
              :show-file-list="false"
              :action="importData.url"
              accept=".xlsx,.xls"
              :on-progress="onImportDataProgress"
              :on-error="onImportDataError"
              :on-success="onImportDataSuccess">
              <el-button :loading="importData.loading" type="primary">{{$t('action.importData')}}</el-button>
            </el-upload>
            <el-button type="primary" @click="onExport"> 导出</el-button>
            <el-button type="primary" @click="onAddSample"> 新增</el-button>
            <el-button type="danger" @click="onDelSample"> 删除</el-button>
          </div>
        </div>
        <div class="m-group_body" style="padding-top: 0;">
          <el-form>
          <el-table
            class="el-table--custom"
            :data="detail"
            row-key="ex_id"
            size="medium"
            stripe
            @row-dblclick="onRowDblclick"
            @selection-change="onSelectionChange">

            <el-table-column
              fixed="left"
              type="selection"
              width="55">
            </el-table-column>

            <el-table-column
              fixed="left"
              prop="NoID"
              label="序号"
              width="55"
              >
              <template slot-scope="{row, column, $index}">
                {{$index+1}}
              </template>
            </el-table-column>

            <el-table-column
              prop="IDCode"
              label="样品编号"
              width="150"
              >
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.IDCode}}</template>
                <el-input v-else v-model="row.IDCode"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              prop="Name"
              label="岩性"
              width="150"
              >
              <template slot-scope="{row, column, $index}">
                <template v-if="!row.ex_isEdit">{{row.Name}}</template>
                <el-input v-else v-model="row.Name"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              prop="SampleType"
              label="样品类别"
              width="150"
              >
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">
                  {{row.SampleType | dictMap(dict.list_lab_SampleType)}}
                </template>
                <el-select v-else v-model="row.SampleType" placeholder="请选择">
                  <el-option
                    v-for="item in dict.list_lab_SampleType"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column
              prop="SampleName"
              label="样品名称"
              width="150"
              >
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.SampleName}}</template>
                <el-input v-else v-model="row.SampleName"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              prop="SampleAdd"
              label="采样地理位置"
              width="150"
              >
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.SampleAdd}}</template>
                <el-input v-else v-model="row.SampleAdd"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              prop="longitude"
              label="采样经度"
              width="150"
              >
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.longitude}}</template>
                <el-input v-else v-model="row.longitude" placeholder="经度-180~180"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              prop="latitude"
              label="采样纬度"
              width="150"
              >
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.latitude}}</template>
                <el-input v-else v-model="row.latitude" placeholder="纬度-90~90"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              prop="TestItems"
              label="分析测试项目"
              width="240">
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.TestItems | dictMap(testItems, {value: 'Name', label: 'ex_name'})}}</template>
                <el-select
                  v-else
                  v-model="row.TestItems"
                  placeholder="请选择"
                  @change="onRowTestItemChange(row)">
                  <template v-if="testItems.length">
                    <el-option
                      v-for="item in testItems"
                      :key="item.Name"
                      :label="item.ex_name"
                      :value="item.Name">
                    </el-option>
                  </template>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column
              prop="ex_reserveCalendar"
              label="预约看板"
              width="200">
              <template slot-scope="{row}">
                <el-button
                  :disabled="!row.TestItems"
                  type="text"
                  @click="onReserveCalendar(row)">
                  查看预约情况
                </el-button>
              </template>
            </el-table-column>

            <el-table-column
              prop="BeginDate"
              label="起始时间"
              width="200">
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.BeginDate}}</template>
                <el-date-picker
                  v-else
                  v-model="row.BeginDate"
                  type="datetime"
                  :disabled="!row.TestItems"
                  :picker-options="{
                    disabledDate: getDisabledDate
                  }"
                  value-format="yyyy-MM-dd hh:mm"
                  style="width: 100%;">
                </el-date-picker>
              </template>
            </el-table-column>

            <el-table-column
              prop="EndDate"
              label="结束时间"
              width="200">
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.EndDate}}</template>
                <el-date-picker
                  v-else
                  v-model="row.EndDate"
                  type="datetime"
                  :disabled="!row.TestItems"
                  :picker-options="{
                    disabledDate: getDisabledDate
                  }"
                  value-format="yyyy-MM-dd hh:mm"
                  style="width: 100%;">
                </el-date-picker>
              </template>
            </el-table-column>

            <el-table-column
              prop="UnitPrice"
              label="单价(元)"
              width="150"
              >
              <template slot-scope="{row}">
                <!-- <template v-if="!row.ex_isEdit">{{row.UnitPrice}}</template>
                <el-input v-else v-model="row.UnitPrice"></el-input> -->
              </template>
            </el-table-column>

            <el-table-column
              prop="Metering"
              label="计量单位"
              width="150"
              >
              <template slot-scope="{row}">
                <!-- <template v-if="!row.ex_isEdit">{{row.Metering}}</template>
                <el-input v-else v-model="row.Metering"></el-input> -->
              </template>
            </el-table-column>

            <el-table-column
              prop="lcount"
              label="数量"
              width="150"
              >
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.lcount}}</template>
                <el-input v-else v-model="row.lcount"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              prop="money"
              label="金额(元)"
              width="150"
              >
              <template slot-scope="{row}">
                <!-- <template v-if="!row.ex_isEdit">{{row.money}}</template>
                <el-input v-else v-model="row.money"></el-input> -->
              </template>
            </el-table-column>

            <el-table-column
              prop="remarks"
              label="备注"
              width="150"
              >
              <template slot-scope="{row}">
                <template v-if="!row.ex_isEdit">{{row.remarks}}</template>
                <el-input v-else v-model="row.remarks"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              fixed="right"
              header-align="center"
              align="center"
              prop="name"
              width="100"
              label="操作">
              <template slot-scope="{ row, column, $index }">
                <el-button v-if="!row.ex_isEdit" size="mini" type="primary" @click="onRowEdit(row)">编辑</el-button>
                <el-button v-else size="mini" type="primary" @click="onRowSave(row)">保存</el-button>
              </template>
            </el-table-column>

          </el-table>
          </el-form>
        </div>
      </div>

      <div class="fm-bottom">
        <el-button type="primary" icon="zm-icon zm-icon-yewuzongliang" @click="onSubmit"> 提交预约单</el-button>
      </div>
    </div>
    <el-dialog class="reverse-dialog" :visible.sync="reverseCalendar.visible">
      <calendar
        :loading="reverseCalendar.loading"
        :scheduleas="reverseCalendar.scheduleas">
      </calendar>
    </el-dialog>
  </div>
</template>

<script>
import JSExportExcel from 'js-export-excel'
import { mapState, mapActions } from 'vuex'
import setting from '@/setting'
import * as util from '@/utils'
import pattern from '@/config/pattern'
import commonApi from '@/api/common'
import labApparatusApi from '@/api/labApparatus'
import labApparatusItemApi from '@/api/labApparatusItem'
import labAppointmentFormPApi from '@/api/labAppointmentFormP'
import Calendar from "@/components/calendar";
export default {
  components: {
    Calendar
  },
  data() {
    return {
      reverseCalendar: {
        visible: false,
        show: false,
        loading: {
          show: false
        },
        scheduleas: [
          {
            id: '1',
            calendarId: '1',
            title: '北京大学 张青',
            category: 'time',
            dueDateClass: '',
            start: '2021-09-15',
            end: '2021-09-17'
          },
          {
            id: '2',
            calendarId: '1',
            title: '中国科学院 李亮',
            category: 'time',
            dueDateClass: '',
            start: '2021-09-07',
            end: '2021-09-08',
            isReadOnly: true
          }
        ]
      },
      uploadAgreement: {
        url: `${setting.baseUrl.api}Sys_User/Upload`,
        fileName: '',
        loading: false,
        success: false
      },
      labApparatus: {}, // 实验仪器
      dict: {
        list_lab_TypeTest: [],
        list_lab_Typelab: [], // 实验室字典
        list_lab_SampleType: [], // 样品类型
        AppointmentType: [] // 预约类型
      },
      applyFm: {
        AppointmentFormNO: util.guid(), // 预约单号
        Name: '', // 预约单名称
        Lab_apparatus_ID: '', // 预约设备id
        Lab_apparatusName: '', // 预约设备名称
        lab_TypelabID: '', // 实验室ID
        lab_TypelabName: '', // 实验室名称
        AppointmentType: '1', // 预约类型
        A_Applicant: '', // 申请人
        A_ApplicationUnit: '', // 申请主体
        A_Date: '', // 申请时间
        A_Principal: '', // 主体负责人
        Tel: '', // 联系电话
        Send_email: '', // 数据发送邮件
        remarks: '', // 申请事由
        remarks2FXMD: '', // 分析目的
        remarks2YQCG: '', // 预期成果
        DContractFile: '', // 文件地址
        AuditStatus: '0' // 审批中
      },
      applyFmRules: {
        A_Applicant: [{ required: true, message: '请输入申请人', trigger: 'blur' }],
        A_Date: [{ required: true, message: '请输入申日期', trigger: 'blur' }],
        A_ApplicationUnit: [{ required: true, message: '请输入申请主体', trigger: 'blur' }],
        A_Principal: [{ required: true, message: '请输入主体负责人', trigger: 'blur' }],
        remarks: [{ required: true, message: '请输入科学问题', trigger: 'blur' }],
        Tel: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { pattern: pattern.phoneOrTel, message: '联系电话格式不正确', trigger: 'blur' }
        ],
        Send_email: [
          { required: true, message: '请输入数据发送邮件', trigger: 'blur' },
          { type: 'email', message: '邮件格式不正确', trigger: 'blur' }
        ],
        DContractFile: [{ required: true, message: '请上传合同', trigger: 'blur' }]
      },
      detail: [],
      detailRules: {
        required: [
          {required: true, message: '必填', trigger: 'blur'}
        ]
      }, // 详情table表单验证
      detailSelection: [],
      testItemRows: [], // 设备下测试项目集合(无名称)
      importData: {
        url: `${setting.baseUrl.api}Lab_ApprovalFormS/Import`,
        loading: false
      }
    }
  },
  computed: {
    ...mapState(['user', 'token']),
    testItems() {
      if (!this.dict.list_lab_TypeTest.length || !this.testItemRows.length) {
        return []
      }
      this.testItemRows.forEach(item => {
        let dict = this.dict.list_lab_TypeTest.find(d => d.key == item.Name);
        if (dict) {
          item.ex_name = dict.value;
        }
      });

      return JSON.parse(JSON.stringify(this.testItemRows));
    }
  },
  inject: ['validateLogin'],
  methods: {
    getDisabledDate(date) {
      // console.log(util.parseTime(date))
      // 时间要大于等于今天
      let cur = new Date();
      let today = new Date(`${cur.getFullYear()}/${cur.getMonth()+1}/${cur.getDate()}`);
      if (date < today) {
        return true;
      }
      // 时间要在测试项目起止时间区间内
      let curRow = this.detail.find(item => item.ex_isEdit === true)
      if (!curRow) {
        return false
      }
      let testItem = this.testItems.find(item => item.Name == curRow.TestItems)
      if (testItem) {
        let begin = new Date(testItem.BeginDate)
        let end = new Date(testItem.EndDate)
        let disabled = date < begin || date > end;
        if (disabled) {
          return true;
        }
        // 置灰已预约的日期
        curRow.ex_reserveList.forEach(item => {
          if (!disabled) {
            let begin = new Date(item.BeginDate.replaceAll('-', '/'));
            let end = new Date(item.EndDate.replaceAll('-', '/'));
            disabled = date >= begin && date <= end;
          }
        });
        if (disabled) {
          return true;
        }
      }
      return false
    },
    async getDict() {
      const dicts = await commonApi.getVueDictionary([
        'list_lab_TypeTest', // 测试项目
        'list_lab_Typelab', // 实验室字典
        'list_lab_SampleType', // 样品类型
        'AppointmentType' // 预约类型
      ])
      if (dicts && dicts.length) {
        this.dict.list_lab_TypeTest = dicts.find(item => item.dicNo === 'list_lab_TypeTest').data
        this.dict.list_lab_Typelab = dicts.find(item => item.dicNo === 'list_lab_Typelab').data
        this.dict.list_lab_SampleType = dicts.find(item => item.dicNo === 'list_lab_SampleType').data
        this.dict.AppointmentType = dicts.find(item => item.dicNo === 'AppointmentType').data
      }
    },
    // 获取实验仪器
    getLabApparatus() {
      this.loading = true
      let wheres = []
      wheres.push({name: 'Lab_apparatus_ID', value: this.$route.query.labApparatusId, displayType: 'text'})
      labApparatusApi.getPageData({
        wheres: JSON.stringify(wheres),
        page: 1,
        rows: 1
      }).then(res => {
        this.loading = false
        this.labApparatus = res.rows[0]

        this.applyFm.Lab_apparatus_ID = this.labApparatus.Lab_apparatus_ID, // 预约设备id
        this.applyFm.Lab_apparatusName = this.labApparatus.Name, // 预约设备名称
        this.applyFm.lab_TypelabID = this.labApparatus.Typelab, // 实验室id
        
        // 申请单名称：仪器名称+日期+4位随机数
        this.applyFm.Name = `${this.labApparatus.Name}_${util.parseTime(new Date(), '{y}{m}{d}')}${util.randomNum(4)}`
      }).catch(err => {
        this.loading = false
      })
    },
    getLabApparatusTestItem() {
      this.loading = true
      let wheres = []
      wheres.push({name: 'Lab_apparatus_ID', value: this.$route.query.labApparatusId, displayType: 'text'})
      labApparatusItemApi.getPageData({
        wheres: JSON.stringify(wheres),
        page: 1,
        rows: 1000
      }).then(res => {
        this.loading = false
        this.testItemRows = res.rows;
      }).catch(err => {
        this.loading = false
      })
    },
    onAddSample() {
      let hasNoSave = this.detail.find(item => item.ex_isEdit === true) ? true : false
      if (hasNoSave) {
        this.$message.warning({
          message: '样品清单存在未保存样品'
        })
        return
      }
      let model = {
        BeginDate: '',
        EndDate: '',
        ID: '',
        IDCode: '',
        NoID: this.applyFm.lab_TypelabID, // 实验仪器id
        Metering: '',
        Name: '',
        SampleAdd: '',
        SampleName: '',
        SampleType: '',
        TestItems: '', // 测试项目
        UnitPrice: '',
        latitude: '',
        lcount: '',
        longitude: '',
        money: '',
        remarks: '',
        status: 0,
        ex_id: util.guid(),
        ex_isEdit: true,
        ex_reserveCalendar: '', // 预约日历看板
      }
      this.detail.push(model)
    },
    onSelectionChange(selection) {
      this.detailSelection = selection
    },
    onDelSample() {
      this.detailSelection.forEach(item => {
        let index = this.detail.findIndex(d => d.ex_id === item.ex_id)
        this.detail.splice(index, 1)
      })
    },
    // 预约明细中测试项目改变
    onRowTestItemChange(row) {
      this.getReserveList(row);
    },
    // 获取预约数据
    getReserveList(row) {
      if (!row.TestItems) {
        return;
      }
      this.loading = true;
      let testItem = this.testItems.find(item => item.Name == row.TestItems);
      let beginDate = util.parseTime(new Date(), '{y}-{m}-{d}');
      let endDate = util.parseTime(testItem.EndDate, '{y}-{m}-{d}');
      let wheres = []
      wheres.push({name: 'NoID', value: row.NoID, displayType: 'text'});
      wheres.push({name: 'TestItems', value: row.TestItems, displayType: 'select'});
      // wheres.push({name: 'BeginDate', value: beginDate, displayType: 'thanorequal'});
      // wheres.push({name: 'EndDate', value: endDate, displayType: 'lessorequal'});
      labApparatusApi.getReserveData({
        wheres: JSON.stringify(wheres),
        page: 1,
        rows: 1000
      }).then(res => {
        this.loading = false
        console.log(res)
        row.ex_reserveList = res.rows;
      }).catch(err => {
        this.loading = false
      })
    },
    // 查看预约看板
    onReserveCalendar(row) {
      this.reverseCalendar.visible = true;
    },
    onExport() {
      let that = this
      // 数据
      let sheetData = this.detail.map((item, index) => {
        return {
          NoID: index + 1, // 序号
          IDCode: item.IDCode, // 样品编号
          Name: item.Name, // 岩性
          SampleType: util.dictMap(item.SampleType, this.dict.list_lab_SampleType), // 样品类别
          SampleName: item.SampleName, // 样品名称
          SampleAdd: item.SampleAdd, // 采样地理位置
          longitude: item.longitude, // 采样经度
          latitude: item.latitude, // 采样纬度
          TestItems: util.dictMap(item.TestItems, this.testItems, {value: 'Name', label: 'ex_name'}), // 分析测试项目
          BeginDate: item.BeginDate, // 起始时间
          EndDate: item.EndDate, // 结束时间
          UnitPrice: item.UnitPrice, // 单价
          Metering: item.Metering, // 计量单位
          lcount: item.lcount, // 数量
          money: item.money, // 金额
          remarks: item.remarks // 备注
        }
      })

      let option = {
        fileName: '样品清单',
        datas: [{
          sheetHeader: ['序号','样品编号','岩性','样品类别','样品名称','采样地理位置','采样经度','采样纬度','分析测试项目','起始时间','结束时间','单价（元）','计量单位','数量','金额（元）','备注'], // 第一行
          sheetData, // 数据
          sheetName:'清单', // sheet名字
          sheetFilter:['NoID','IDCode','Name','SampleType','SampleName','SampleAdd','longitude','latitude','TestItems','BeginDate','EndDate','UnitPrice','Metering','lcount','money','remarks'] //列过滤
        }]
      }

      let toExcel=new JSExportExcel(option)
      toExcel.saveExcel()
    },
    onDownloadImportTpl(val) {
      let url = location.origin + '/static/样品清单(模板).xlsx'
      window.open(url, '_blank')
    },
    onImportDataProgress() {
      this.importData.loading = true
    },
    onImportDataError(res) {
      console.log(res)
      this.importData.loading = false
      this.$message.error({
        message: res.message
      })
    },
    onImportDataSuccess(res, file) {
      this.importData.loading = false
      if (!res.status) {
        this.$message.error({
          message: res.message
        })
        return
      }
      
      this.$message.success({
        message: '导入成功！'
      })

      res.data.forEach(item => {
        let model = {
          BeginDate: item.beginDate,
          EndDate: item.endDate,
          ID: '',
          IDCode: item.idCode,
          NoID: this.applyFm.lab_TypelabID, // 实验仪器id
          Metering: '',
          Name: item.name,
          SampleAdd: item.sampleAdd,
          SampleName: item.sampleName,
          SampleType: item.sampleType,
          TestItems: item.testItems,
          UnitPrice: '',
          latitude: item.latitude,
          lcount: item.lcount,
          longitude: item.longitude,
          money: '',
          remarks: item.remarks,
          status: 0,
          ex_id: util.guid(),
          ex_isEdit: false,
          ex_reserveCalendar: '', // 预约日历看板
        }
        this.detail.push(model)
      })
    },
    onRowDblclick(row) {
      this.getReserveList(row);
      row.ex_isEdit = true;
    },
    onRowEdit(row) {
      this.detail.forEach(item => {
        item.ex_isEdit = false
      })
      row.ex_isEdit = true
      this.getReserveList(row);
    },
    onRowSave(row) {
      row.ex_isEdit = false
    },
    onSubmit() {
      this.$refs['applyFm'].validate((valid) => {

        if (!valid) {
          return
        }

        if (!this.detail.length) {
          this.$message.warning({
            message: '请添加至少一个样品信息'
          })
          return
        }

        let hasNoSave = this.detail.find(item => item.ex_isEdit === true) ? true : false
        if (hasNoSave) {
          this.$message.warning({
            message: '样品清单存在未保存样品'
          })
          return
        }

        this.doSubmit()
      })
    },
    doSubmit() {
      // 序号处理
      this.detail.forEach((item, index) => {
        item.IDCode = index + 1
      })
      // 表单实验室名称赋值
      let typeLab = this.dict.list_lab_Typelab.find(item => item.key == this.applyFm.lab_TypelabID)    ;
      if (typeLab) {
        this.applyFm.lab_TypelabName = typeLab.value
      }
      labAppointmentFormPApi.add({
        delKeys: null,
        detailData: this.detail,
        mainData: this.applyFm
      })
      .then(res => {
        if(!res.status) {
          this.$message.error({
            message: res.message
          })
          return
        }
        this.$message.success({
          message: '提交成功！'
        })
        this.$router.push('/personal/reserve')
      })
      .catch(err => {})
    },
    onAgreementDownload(flag) {
      labAppointmentFormPApi.downLoadAgreement({
        flag, // 动作标识 对内对外
        labId: this.$route.query.labApparatusId // 实验室Id
      });
    },
    onUploadAgreementProgress() {
      this.uploadAgreement.loading = true
    },
    onUploadAgreementError(err) {
      this.uploadAgreement.loading = false
    },
    onUploadAgreementSuccess(res, file) {
      this.uploadAgreement.loading = false
      if (res.status) {
        this.uploadAgreement.success = true
        this.uploadAgreement.fileName = file.name
        this.applyFm.DContractFile = res.data + file.name
        this.$message.success({
          message: '上传成功！'
        })
      }
    },
    onUploadAgreementExceed() {
      this.$message.error({
        message: '如需重新上传，请删除已上传合同！'
      })
    },
    onUploadAgreementRemove() {
      this.applyFm.DContractFile = ''
    }
  },
  mounted() {
    this.validateLogin()
    this.getDict()
    this.getLabApparatus()
    this.getLabApparatusTestItem()
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .mark-title {
      padding: 30px 0 10px 0;
      font-size: 22px;
      color: #909399;
      &_icon {
        margin-right: 8px;
        font-size: 30px;
      }
    }
    .btn-import {
      display: inline-block;
      margin-right: 10px;
    }
    .el-table--custom {
      /deep/ .el-table__header {
        border-top: none;
      }
    }
    .fm-bottom {
      padding: 20px 0;
      text-align: right;
      border-top: 1px solid #eee;
    }
    /deep/ .el-select {
      width: 100%;
    }
    .tip-icon {
      position: absolute;
      font-size: 18px;
      top: 12px;
      right: 5px;
      color: #888;
      cursor: pointer;
      transition: all .2s linear;
      &:hover {color: #555;}
    }
  }
  .reverse-dialog {
    /deep/ .el-dialog__body {
      padding: 20px 0 0 0;
    }
  }
</style>
